<template>
    <div class="status-checker">
        <temlate v-if="done">
            {{ $t('index.checker.done') }}
        </temlate>
        <template v-else>
            <template v-if="checking">
                {{ $t('index.checker.checking') }}
            </template>
            <template v-else>
                {{ $t('index.checker.checking_in') }}
                {{ secondsToCheck }}
                {{ $t('index.checker.seconds') }}
            </template>
        </template>
    </div>
</template>

<script>
export default {
    name: "StatusChecker",
    props: ['historyId'],
    data() {
        return {
            done: false,
            checking: false,
            secondsToCheck: 60,
            intervalCheck: null,
            intervalCounter: null,

            checkEverySeconds: 60,
        }
    },
    methods: {

    },
    created() {
        this.intervalCheck = setInterval(() => {
            this.checking = true;
            console.log(this.historyId);
            console.log('/api/info/status/'+this.historyId);
            axios.get('/api/info/status/'+this.historyId).then((response) => {
                if(response.data.status === 'success') {
                    if(response.data.data.status === 'active') {
                        clearInterval(this.intervalCheck);
                        clearInterval(this.intervalCounter);
                        this.done = true;
                        this.$emit('registered', {historyId: response.data.data.history_id});
                    }
                    // TODO add failed status check, display error
                    this.checking = false;
                    this.secondsToCheck = this.checkEverySeconds;
                }
            });

        }, this.checkEverySeconds * 1000);

        this.intervalCounter = setInterval(() => {
            this.secondsToCheck--;
        },  1000);
    }
}
</script>

<style scoped>

</style>
