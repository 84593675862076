<template>
    <div class="col-12">
        <div class="inner">
            <h2>Invalid MAC address</h2>
            <p>The MAC address of your device is invalid and can't be registered in our network.
                Please contact your device's vendor and ask them how to fix this issue.</p>
            <p>Your MAC: <strong>{{ mac }}</strong></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "InvalidMac",
    props: ['mac'],
}
</script>
