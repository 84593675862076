<template>
    <div class="col-12 heading">
        <div class="inner">
            <div class="language-switcher">
              <img src="/img/flag/cs.svg" @click="switchTo('cs')" v-if="laravel.locale === 'en'"/>
              <img src="/img/flag/en.svg" @click="switchTo('en')" v-if="laravel.locale === 'cs'"/>
            </div>
            <h1>Network Device Registration</h1>
            <p>Before accessing the internet connection, you need to register your device using this app.</p>
            <p>Please follow the few simple steps.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            language: laravel.locale,
        };
    },
    methods: {
        switchTo() {

        }
    },
}
</script>
