<template>
    <div class="col-12 heading">
        <div class="inner">
            <div class="alert alert-warning">
                <h2>{{ $t('notice.heading') }}</h2>
                <p  class="mt-2">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Notice",
    props: ['message']
}
</script>
