
import './bootstrap';
import Vue from 'vue';
import App from './views/App';
import router from './routes';
import BootstrapVue from "bootstrap-vue";
import VueI18n from 'vue-i18n';
import vueCookies from 'vue-cookies';
import cs from "./lang/cs";
import en from "./lang/en";
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueI18n);
const messages = {
    'en': en,
    'cs': cs,
};
const i18n = new VueI18n({
    locale: window.laravel.locale,
    fallbackLocale: 'cs',
    messages
});

axios.defaults.headers.common = {
    'X-CSRF-TOKEN': laravel.csrfToken,
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + laravel.apiToken,
    'ApiLocale': laravel.locale,
};
axios.defaults.timeout = 1000*60*10;

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

Vue.use(vueCookies)

const app = new Vue({
    el: '#app',
    router,
    i18n,
    render: h => h(App),
});

export default app;
