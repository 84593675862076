<template>
    <div class="mt-3 mb-3">

        <router-view />

    </div>
</template>

<script>

    export default {
        name: "App",
        watch: {
            '$route' (to, from) {
                document.title = to.meta.title || 'Registrace zařízení'
            }
        },
    }
</script>
