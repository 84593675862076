<template>
    <div class="col-12">
        <div class="inner text-center">
            <b-spinner/>
            <br>
            {{ $t('loading.text') }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>
