import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';

import IndexPage from "./pages/IndexPage";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: IndexPage,
            meta: {
                title: 'Registration'
            }
        },

    ],
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default router;
